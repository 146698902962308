module.exports = [{
      plugin: require('/Users/jose/Desktop/projects/projs_jamstack/jbonigomes.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/jose/Desktop/projects/projs_jamstack/jbonigomes.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false,"pageTransitionDelay":0,"trackingId":"UA-62901832-2"},
    },{
      plugin: require('/Users/jose/Desktop/projects/projs_jamstack/jbonigomes.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
